import {
  country
} from "../../js/path";

export default {
  data() {
    return {
      country_id: null,
      pageNo: null,
      fields: [{
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "sortname",
        },
        {
          key: "name",
        },
        {
          key: "phonecode",
        },
        {
          key: "edit",
        },
      ],
      form: {
        name: "",
        sortname: "",
        phonecode: "",
        flag: null,
      },
      tableData: [],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      key: 0,
      currentPage: 1,
      params: "",
      image_name:"",
      image_name_url:"",
      edit: {
        image_name_url: null,
     },
    };
  },
  methods: {
    searchFor() {
      if (this.filter.length > 1)
        this.fetchData("search");
      else if (this.filter.length == 0)
        this.fetchData('search');
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData('search');
        }
      } else if (this.filter.length == 0) this.fetchData('search');
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchData(pagination = null) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = country.countryUrl;
        if (pagination == "search") {
          url = country.countryUrl + "?search=" + this.filter;
        } else if (pagination) {
          url = url + "?page=" + pagination;
        }
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.tableData = responseData;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchCountry(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = country.countryUrl + "/" + id;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;

          this.country_id = responseData.id;
          if (responseData.name != "" && responseData.name != null) {
            this.form.name = responseData.name;
          }

          if (responseData.flag != "" && responseData.flag != null) {
            this.edit.image_name_url = responseData.flag;
            this.image_name=responseData.flag;
          }


          if (responseData.sortname != "" && responseData.sortname != null) {
            this.form.sortname = responseData.sortname;
          }

          if (responseData.phonecode != "" && responseData != null) {
            this.form.phonecode = responseData.phonecode;
          }
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    readFile(e, txt) {
      if (txt == "image_name") {
         this.image_name = e.target.files[0];
         if (this.image_name.size > 2 * 1000 * 1000) {
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: "Uploaded File is More than 2MB",
            });
            this.$refs.image_name.reset();
            this.image_name_url = "";
            this.edit.image_name_url = "";
            this.image_name = "";
            return false;
         } else {
            this.image_name_url = URL.createObjectURL(this.image_name);
            this.edit.image_name_url = "";
         }
         return true;
      }
   },
    async submitData() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields"
          });
          return false;
        }
        this.$store.commit("loader/updateStatus", true);
        let url = country.countryUrl;
        if (this.$route.name == 'edit-country') {
          url = country.countryUrl + '/' + this.country_id;
        }
        let dataAppend = new FormData();
        dataAppend.append('name', this.form.name);
        dataAppend.append('sortname', this.form.sortname);
        dataAppend.append('phonecode', this.form.phonecode);
        if (typeof this.image_name === "string") {
          if (this.image_name.includes("https://")) {
             this.image_name = "";
          }
        }

       if (this.image_name) {
          console.log("image_name",this.image_name);
          dataAppend.append("flag", this.image_name);
       }
       for (var pair of dataAppend.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
        

        if (this.$route.name == 'edit-country') {
          dataAppend.append('_method', 'put');
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push("/country");
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) {
          this.fetchData(value);
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  created() {
    if (
      this.$route.name == "add-country" ||
      this.$route.name == "edit-country"
    ) {
      if (this.$route.name == "edit-country") {
        this.fetchCountry(this.$route.params.id);
      }
    } else {
      this.fetchData();
    }
  },
};